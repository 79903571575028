<script>
import axios from "axios"
import Navbar from '../components/Navbar.vue'
import Footer from '../components/Footer.vue'
export default{
components:{
    Navbar,Footer
},
data(){
    return{
        doctors:[]
    }
},
methods:{
    async getDoctors(){
        const response = await axios.get('https://api.doctosoft.com/index.php/api/doctor')
        this.doctors = response.data
    },
        getAppointment(id) {
      this.$router.push("/appointment/" + id);
    },
    doctorChat(id) {
      this.$router.push("/chat/" + id);
    },
},
created(){
    this.getDoctors()
}
}

</script>


<template>
   <Navbar/>
<div class="container head-text">
    <h3>All doctors</h3>
</div>
<div class="cards container">
    <div class="card-item" v-for="doctor in doctors" :key="doctor.id">
        <div class="card-img">
          <img src="../../../assets/images/doctor2.jpg" />
        </div>
        <p>
          {{ doctor.name }} <span>{{ doctor.expert }} </span>
        </p>
        <div class="btns">
            <b-button
              variant="secondary"
              class="submitBtn"
              @click="getAppointment(doctor.id)"
              >Book Now
            </b-button>
            <b-button
              variant="success"
              class="submitBtn"
              @click="doctorChat(doctor.id)"
              >Talk now!
            </b-button>
          </div>
      </div>
</div>


   <Footer/>
</template>

<style scoped>
.head-text{
    margin-top: 25px;
}
.cards {

  display: grid;
  grid-template-columns: auto auto auto auto;
  grid-row-gap: 15px;
  justify-content: space-around;
  align-items: center;
  padding: 2rem 2rem;
}

.card-item {
  height: 300px;
  width: 220px;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.12);
  overflow: hidden;
  transition: 0.3s ease;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}
.card-item:hover {
  transform: scale(1.04);
}

.card-item .btns{
  display: flex;
  gap: 10px;
}
.card-item .btns button{
  padding: 5px 10px;
  margin-bottom: 8px;
}
.card-item p {
  text-align: center;
  font-size: 18px;
  margin: 0 !important;
}

.card-item p span {
  display: block;
  color: #ec5a60;
  font-size: 14px;
}
.card-item b-button {
  width: fit-content;
  margin-bottom: 10px;
}
.card-img {
  height: 70%;
  width: 100%;
  overflow: hidden;
}

.card-img img {
  width: 100%;
  height: 100%;
}
</style>